import 'firebase/compat/auth'
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import * as ciap from 'gcip-iap'

const tosUrl = 'https://trendentify.de/impressum/'
const privacyPolicyUrl = 'https://trendentify.de/datenschutz/'
const apiKey = process.env.API_KEY
const projectId = process.env.PROJECT_ID

const configs = {
    [`${apiKey}`]: {
        authDomain: `${projectId}.firebaseapp.com`,
        displayMode: 'optionFirst',
        callbacks: {
            // The callback to trigger when the sign-in page
            // is shown.
            signInUiShown: _tenantId => {
                // Show tenant title and additional display info.
            },
            beforeSignInSuccess: user => {
                // Do additional processing on user before sign-in is
                // complete.
                return Promise.resolve(user)
            }
        },
        tenants: {
            _: {
                displayName: 'Trendentify',
                iconUrl: 'https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/anonymous.png',
                logoUrl: '',
                buttonColor: '#007bff',
                immediateFederatedRedirect: true,
                signInFlow: 'popup',
                signInOptions: [
                    {
                        provider: 'microsoft.com',
                        providerName: 'Microsoft',
                    }
                ],
                tosUrl,
                privacyPolicyUrl
            }
        },
        tosUrl,
        privacyPolicyUrl
    }
}

const handler = new firebaseui.auth.FirebaseUiHandler('#firebaseui-auth-container', configs)
handler.languageCode = navigator.language.split('-')[0]
const ciapInstance = new ciap.Authentication(handler)
ciapInstance.start()
